import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Checkbox, Spacer, DateRangePicker, Chip, useDisclosure } from '@nextui-org/react';

const adjustToCanaryTimezone = (date) => {
    if (!date) return null;
    const adjustedDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0
    ));
    return adjustedDate;
};

const AvailabilityManagement = () => {
  const [isSingleScooterUnavailable, setIsSingleScooterUnavailable] = useState(false);
  const [isDoubleScooterUnavailable, setIsDoubleScooterUnavailable] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [unavailableList, setUnavailableList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const [itemToDelete, setItemToDelete] = useState(null);

  // Загружаем недоступные скутеры при загрузке компонента
  useEffect(() => {
    const fetchUnavailableScooters = async () => {
      try {
        const token = localStorage.getItem('token'); // Убедитесь, что токен добавлен
        const response = await fetch('https://hotticketsserver-production.up.railway.app/api/unavailable-scooters', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setUnavailableList(data); // обновляем список недоступных скутеров
      } catch (error) {
        console.error('Failed to fetch unavailable scooters:', error);
      }
    };

    fetchUnavailableScooters();
  }, []);

  // Преобразуем и отправляем даты в правильном формате
  const prepareDatesForServer = (dates) => {
    if (!dates || !dates.start || !dates.end) return null;
    const startDate = adjustToCanaryTimezone(new Date(dates.start.toDate()));
    const endDate = adjustToCanaryTimezone(new Date(dates.end.toDate()));
    return {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    };
  };

  // Отправляем данные о недоступности на сервер
  const handleSubmit = async () => {
    if (!isSingleScooterUnavailable && !isDoubleScooterUnavailable) {
      console.error('At least one scooter type must be selected');
      return;
    }

    setIsLoading(true);

    const dates = prepareDatesForServer(selectedDates);
    if (!dates) {
      console.error('Invalid date range selected');
      setIsLoading(false);
      return;
    }

    const unavailableScooters = {
      single: isSingleScooterUnavailable ? 0 : null,
      double: isDoubleScooterUnavailable ? 0 : null,
    };

    const newUnavailable = {
      startDate: dates.start,
      endDate: dates.end,
      unavailableScooters,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://hotticketsserver-production.up.railway.app/api/unavailable-scooters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newUnavailable),
      });

      if (response.ok) {
        const result = await response.json();
        setUnavailableList([...unavailableList, { ...newUnavailable, _id: result._id }]); // Сохраняем ID для удаления
      } else {
        console.error('Failed to save unavailable scooters');
      }
    } catch (error) {
      console.error('Failed to save unavailable scooters:', error);
    }

    setIsLoading(false);
    onOpenChange(false); // Закрываем модальное окно
  };

  // Удаление заглушки для недоступных скутеров
  const handleDeleteUnavailable = async () => {
    if (!itemToDelete || !itemToDelete._id) {
      console.error('No valid item to delete');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://hotticketsserver-production.up.railway.app/api/unavailable-scooters/${itemToDelete._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setUnavailableList(prevList => prevList.filter(item => item._id !== itemToDelete._id));
        setItemToDelete(null);
      } else {
        console.error('Failed to delete unavailable scooter');
      }
    } catch (error) {
      console.error('Failed to delete unavailable scooter:', error);
    }
    onDeleteOpenChange(false); // Закрываем модальное окно удаления
  };

  // Открытие модального окна для удаления
  const confirmDelete = (item) => {
    setItemToDelete(item);
    onDeleteOpen(true);
  };

  // Сброс значений при закрытии модального окна
  const resetForm = () => {
    setSelectedDates(null);
    setIsSingleScooterUnavailable(false);
    setIsDoubleScooterUnavailable(false);
  };

  return (
    <div>

{/* Список недоступных скутеров */}
<div>
  {unavailableList.length === 0 ? (
    <div style={{ padding: '16px', backgroundColor: '#F4FBF7', border: '1px solid #C2EBD5', borderRadius: '12px', color: '#0E4E30', fontWeight: '500', maxWidth: 'fit-content' }}>
      <p>All scooters are available now</p>
    </div>
  ) : (
    unavailableList.map((item, index) => (
      <div key={item._id || index}> {/* Добавлен index как резервный ключ */}
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center', maxWidth: '588px', backgroundColor: '#F5F7F9', padding: '16px', borderRadius: '12px', border: '1px solid #CFD6DD', marginTop: '12px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <p style={{ fontWeight: '500' }}>
            Unavailable from {new Date(item.startDate).toLocaleDateString()} to{' '}
            {new Date(item.endDate).toLocaleDateString()}
          </p>
          <div style={{ display: 'flex', gap: '8px' }}>
            {item.unavailableScooters.single === 0 && (
              <Chip color="default" size="md">Single Scooter</Chip>
            )}
            {item.unavailableScooters.double === 0 && (
              <Chip color="default" size="md">Double Scooter</Chip>
            )}
          </div>
          </div>
          <Button color="primary" variant="bordered" onPress={() => confirmDelete(item)}>
            Restore
          </Button>
        </div>
      </div>
    ))
  )}
</div>


      <Spacer y={8} />

      {/* Кнопка для управления наличием */}
      <Button color="danger" onPress={onOpen}>
      Restrict availability
      </Button>

      {/* Модальное окно для добавления недоступных скутеров */}
      <Modal isOpen={isOpen} onOpenChange={(isOpen) => {
        onOpenChange(isOpen);
        if (!isOpen) resetForm(); // Сброс при закрытии
      }}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Disable availability</ModalHeader>
              <ModalBody>
                <DateRangePicker
                  label="Choose dates"
                  placeholder="Select date range"
                  onChange={(dates) => setSelectedDates(dates)}
                />
                <Spacer y={2} />
                <Checkbox
                  isSelected={isSingleScooterUnavailable}
                  onChange={() => setIsSingleScooterUnavailable(!isSingleScooterUnavailable)}
                >
                  Single Scooter
                </Checkbox>
                <Checkbox
                  isSelected={isDoubleScooterUnavailable}
                  onChange={() => setIsDoubleScooterUnavailable(!isDoubleScooterUnavailable)}
                >
                  Double Scooter
                </Checkbox>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={handleSubmit}
                  isDisabled={!isSingleScooterUnavailable && !isDoubleScooterUnavailable}
                  isLoading={isLoading}
                >
                  Save
                </Button>
                <Button variant="light" color="danger" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Модальное окно для подтверждения удаления */}
      <Modal isOpen={isDeleteOpen} onOpenChange={onDeleteOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Restore availability</ModalHeader>
              <ModalBody>
                <p>
                Scooters will be available again starting from{' '}
                  {new Date(itemToDelete?.startDate).toLocaleDateString()} to{' '}
                  {new Date(itemToDelete?.endDate).toLocaleDateString()}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={handleDeleteUnavailable}>
                  Restore
                </Button>
                <Button variant="light" color="danger" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AvailabilityManagement;
