// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // Главный экран
      "title": "Rent a mobility scooter in Tenerife South",
      "description": "Discover the freedom of moving around Tenerife with our scooter rental service",
      // Букинг форма
      "rental_period": "Rental period",
      "pickup_time": "Pickup time",
      "return_time": "Return time",
      "select_placeholder": "Select time",
      "book_now": "Book now",
      "book_for": "Book for {{count}} days",
      "book_for_plural": "Book for {{count}} days",
      "extra_day_message": "ℹ️ Your return time exceeds 24 hours, so an additional day has been added to the rental",
      "date_error_message": "Select a rental period",
      "pickup_time_error_message": "Select a pickup time",
      "return_time_error_message": "Select a return time",
      "checking_availability": "Checking availability",
      // Меню
      "single_scooter": "Single mobility scooter",
      "double_scooter": "Double mobility scooter",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "About us",
      "contacts": "Contacts",
      "choose_date": "Choose dates",
      // Карточка скутера
      "scooter_sigle_from_price": "from 10€/day",
      "scooter_double_from_price": "from 20€/day",
      // Таблица с ценами
      "duration": "Days",
      "price_name": "Price",
      "day_single": "day",
      "day_plural": "days",
      "tariff_name": "Rental rates",
      "single_one": "Single",
      "double_one": "Double",
      // Локация офиса
      "our_office": "Our office",
      "hours_title": "WORKING HOURS",
      "working_days": "Monday to Sunday",
      "working_hours": "9:30 till 17:00",
      "adress_title": "ADRESS",
      "close": "Close",
      "open_maps": "Open in Google Maps",
      "map": "Map",
      "how_get_button": "How to get there?",
      // Футер
      "footer_heading": "A top mobility scooter rental service in Tenerife HotTickets",
      "copyright": "All rights are reserved",
      "aviso_legal": "Legal notice",
      "privacidad": "Privacy policy",
      "cookies": "Cookie policy",
      "terms_of_rent": "Terms of rent",
      "whatsapp_btn": "Write on WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Choose your preferred dates for scooter rental",
      "homepage": "Homepage",
      // О нас
      "about_text": "Welcome to the leading mobility scooter rental agency in south Tenerife. With years of experience, we ensure your comfort and safety with our reliable scooters. Our reputation is built on trust and excellent service. Explore Tenerife with ease and confidence on our well-maintained scooters. Choose us for a hassle-free rental experience focused on your mobility and enjoyment.",
      "review_button": "Read 220+ reviews",
      // Бронирование 
      "choose_scooter": "Choose scooter",
      "choose_scooter_heading": "Select scooter type and quantity",
      "select_btn": "Select",
      "continue": "Continue",
      "add_to_selection": "Add to selection",
      "total_amount": "Total",
      "prepayment_online": "Online prepayment",
      "payment_on_pickup": "Payment on pickup",
      "no_scooters_available": "⚠️ Unfortunately, there are no available scooters. Please try selecting different dates.",
       // Персональные данные
     "first_name": "First name",
     "name_description": "As on ID",
      "last_name": "Last name",
      "hotel": "Hotel",
      "hotel_description": "Where you're staying?",
      "cell_number": "Phone number",
      "cell_number_description": "We'll message you on WhatsApp to confirm",
      "email": "Email",
      "email_description": "We'll send a confirmation email",
      "personal_data": "Personal data",
      "personal_info_heading": "Almost there 😊\nFill in your information",
      "required_field": "This field is required",
      "agree_to": "I have read and agree to",
      "terms_rent": "the terms of rent",
      // Подтверждение данных
      "confirmation_heading": "Please check the details and make a prepayment",
      "booking_info": "Booking Information",
      "rental_cost": "Rental cost",
      "start_date": "Start date",
      "end_date": "End date",
      "return_duration": "Rental duration",
      "prepayment_now": "Prepayment now",
      "deposit_on_pickup": "Deposit on pickup",
      "pay_btn": "Pay 20€",
      "edit": "Edit",
    }
  },
  es: {
    translation: {
      // Главный экран
      "title": "Alquila un scooter de movilidad en Tenerife Sur",
      "description": "Descubre la libertad de moverte por Tenerife con nuestro servicio de alquiler de scooters",
      //Букинг форма
      "rental_period": "Período de alquiler",
      "pickup_time": "Hora de recogida",
      "return_time": "Hora de devolución",
      "select_placeholder": "Seleccionar",
      "book_now": "Reservar ahora",
      "book_for": "Reservar para {{count}} días",
      "book_for_plural": "Reservar para {{count}} días",
      "extra_day_message": "ℹ️ Su hora de devolución excede las 24 horas, por lo que se ha añadido un día adicional al alquiler",
      "date_error_message": "Selecciona un período de alquiler",
      "pickup_time_error_message": "Selecciona una hora de recogida",
      "return_time_error_message": "Selecciona una hora de devolución",
      "checking_availability": "Verificando disponibilidad",
      // Меню
      "single_scooter": "Scooter de movilidad individual",
      "double_scooter": "Scooter de movilidad doble",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "Sobre nosotros",
      "contacts": "Contactos",
      "choose_date": "Eligir fechas",
      // Карточка скутера
      "scooter_sigle_from_price": "desde 10€/día",
      "scooter_double_from_price": "desde 20€/día",
      // Таблица с ценами
      "duration": "Días",
      "price_name": "Precio",
      "day_single": "día",
      "day_plural": "días",
      "tariff_name": "Tarifas de alquiler",
      "single_one": "Individual",
      "double_one": "Doble",
      // Локация офиса
      "our_office": "Nuestra oficina",
      "hours_title": "HORARIO",
      "working_days": "Lunes a Domingo",
      "working_hours": "9:30 a 17:00",
      "adress_title": "DIRECCIÓN",
      "close": "Cerrar",
      "open_maps": "Abrir en Google Maps",
      "map": "Mapa",
      "how_get_button": "¿Cómo llegar?",
      // Футер 
      "footer_heading": "Un servicio de alquiler de scooters de movilidad de primera en Tenerife HotTickets",
      "copyright": "Todos los derechos reservados",
      "aviso_legal": "Aviso legal",
      "privacidad": "Política de privacidad",
      "cookies": "Política de cookies",
      "terms_of_rent": "Términos de alquiler",
      "whatsapp_btn": "Escribir en WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Elige tus fechas preferidas para el alquiler de scooters",
      "homepage": "Inicio",
      // О нас
      "about_text": "Bienvenido a la agencia líder en alquiler de scooters de movilidad en el sur de Tenerife. Con años de experiencia, garantizamos su comodidad y seguridad con nuestros scooters fiables. Nuestra reputación se basa en la confianza y el excelente servicio. Explore Tenerife con facilidad y confianza en nuestros scooters bien mantenidos. Elíjanos para una experiencia de alquiler sin complicaciones centrada en su movilidad y disfrute.",
      "review_button": "Lee más de 220 reseñas",
      // Бронирование 
      "choose_scooter": "Eligir scooter",
      "choose_scooter_heading": "Elige el tipo de scooter y la cantidad",
      "continue": "Continuar",
      "select_btn": "Elegir",
      "add_to_selection": "Añadir a la selección",
      "total_amount": "Total",
      "prepayment_online": "Prepago en línea",
      "payment_on_pickup": "Pago al recoger",
      "no_scooters_available": "⚠️ Desafortunadamente, no hay scooters disponibles. Por favor, intente seleccionar otras fechas.",
      // Персональные данные
      "first_name": "Nombre",
      "last_name": "Apellidos",
      "hotel": "Hotel",
      "hotel_description": "¿Dónde te alojas?",
      "cell_number": "Móvil",
      "cell_number_description": "Te enviaremos un mensaje por WhatsApp para confirmar",
      "email": "Correo electrónico",
      "email_description": "Te enviaremos un correo de confirmación",
      "personal_data": "Datos personales",
      "personal_info_heading": "¡Casi terminado! 😊\nRellena tu información",
      "required_field": "Este campo es obligatorio",
      "agree_to": "He leído y estoy de acuerdo con",
      "terms_rent": "los términos de alquiler",
      // Подтверждение данных
      "confirmation_heading": "Por favor, verifica los datos y realiza el prepago",
      "booking_info": "Información de la reserva",
      "rental_cost": "Costo de alquiler",
      "start_date": "Fecha de inicio",
      "end_date": "Fecha de finalización",
      "return_duration": "Duración del alquiler",
      "prepayment_now": "Prepago ahora",
      "deposit_on_pickup": "Depósito al recoger",
      "pay_btn": "Pagar 20€",
      "edit": "Editar",
    }
  },
  ru: {
    translation: {
      // Главный экран
      "title": "Аренда мобильных скутеров на юге Тенерифе",
      "description": "Откройте для себя свободу передвижения по Тенерифе с нашим сервисом аренды скутеров",
      // Букинг форма
      "rental_period": "Период аренды",
      "pickup_time": "Время получения",
      "return_time": "Время возврата",
      "select_placeholder": "Выбрать",
      "book_now": "Забронировать сейчас",
      "book_for": "Забронировать на {{count}} дней",
      "book_for_plural": "Забронировать {{count}} дней",
      "extra_day_message": "ℹ️ Выбранное время возврата превышает 24 часа, поэтому к аренде добавлен дополнительный день",
      "date_error_message": "Выберите период аренды",
      "pickup_time_error_message": "Выберите время получения",
      "return_time_error_message": "Выберите время возврата",
      "checking_availability": "Проверяем наличие",
      // Меню
      "single_scooter": "Одноместный скутер",
      "double_scooter": "Двухместный скутер",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "О нас",
      "contacts": "Контакты",
      "choose_date": "Выбрать даты",
      // Карточка скутера
      "scooter_sigle_from_price": "от 10€ в день",
      "scooter_double_from_price": "от 20€ в день",
      // Таблица с ценами
      "duration": "Количество дней",
      "price_name": "Тариф",
      "day_single": "день",
      "day_plural": "дней",
      "tariff_name": "Тарифы аренды",
      "single_one": "Одноместный",
      "double_one": "Двухместный",
      // Локация офиса
      "our_office": "Наш офис",
      "hours_title": "РАБОТАЕМ",
      "working_days": "С понедельника по воскресенье",
      "working_hours": "9:30 — 17:00",
      "adress_title": "АДРЕС",
      "close": "Закрыть",
      "open_maps": "Открыть в Google Maps",
      "map": "Карта",
      "how_get_button": "Как к вам добраться?",
      // Футер 
      "footer_heading": "Лучший сервис по аренде мобильных скутеров на Тенерифе HotTickets",
      "copyright": "Все права защищены",
      "aviso_legal": "Юридическое уведомление",
      "privacidad": "Политика конфиденциальности",
      "cookies": "Политика использования файлов cookie",
      "terms_of_rent": "Условия аренды",
      "whatsapp_btn": "Написать в WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Выберите предпочтительные даты для аренды скутера",
      "homepage": "Главная",
      // О нас
      "about_text": "Добро пожаловать в ведущую компанию по аренде мобильных скутеров на юге Тенерифе. Многолетний опыт позволяет нам обеспечивать ваш комфорт и безопасность с помощью надежных скутеров. Наша репутация основана на доверии и высоком уровне обслуживания. Исследуйте Тенерифе легко и уверенно на наших хорошо обслуженных скутерах. Выбирайте нас для удобной аренды, ориентированной на вашу мобильность и комфорт.",
      "review_button": "Прочитайте 220+ отзывов",
      // Бронирование 
      "choose_scooter": "Выбрать скутер",
      "choose_scooter_heading": "Выберите тип скутера и количество",
      "continue": "Продолжить",
      "select_btn": "Выбрать",
      "add_to_selection": "Добавить к выбору",
      "total_amount": "Итого",
      "prepayment_online": "Предоплата онлайн",
      "payment_on_pickup": "Оплата при получении",
      "no_scooters_available": "⚠️ К сожалению, нет свободных скутеров. Пожалуйста, попробуйте выбрать другие даты.",
      // Персональные данные
      "first_name": "Имя",
      "last_name": "Фамилия",
      "hotel": "Отель",
      "hotel_description": "Где вы остановились?",
      "cell_number": "Телефон",
      "cell_number_description": "Напишем в WhatsApp для подтверждения бронирования",
      "email": "Почта",
      "email_description": "Пришлём письмо с подтверждением бронирования",
      "personal_data": "Персональные данные",
      "personal_info_heading": "Осталось совсем чуть-чуть 😊\nЗаполните информацию о себе",
      "required_field": "Поле обязательно для заполнения",
      "agree_to": "Я прочитал и согласен с",
      "terms_rent": "условиями аренды",
      // Подтверждение данных
      "confirmation_heading": "Пожалуйста, проверьте данные и внесите предоплату",
      "booking_info": "Информация о бронировании",
      "rental_cost": "Стоимость аренды",
      "start_date": "Начало аренды",
      "end_date": "Окончание аренды",
      "return_duration": "Количество дней аренды",
      "prepayment_now": "Предоплата сейчас",
      "deposit_on_pickup": "Депозит при получении",
      "pay_btn": "Оплатить 20€",
      "edit": "Изменить"
    }
  },
  de: {
    translation: {
      // Главный экран
      "title": "Mieten Sie einen Mobilitätsroller im Süden von Teneriffa",
      "description": "Entdecken Sie die Freiheit, sich mit unserem Rollerverleih auf Teneriffa zu bewegen",
      // Букинг форма
      "rental_period": "Mietzeitraum",
      "pickup_time": "Abholzeit",
      "return_time": "Rückgabezeit",
      "select_placeholder": "Wählen",
      "book_now": "Jetzt buchen",
      "book_for": "Für {{count}} Tage buchen",
      "book_for_plural": "Für {{count}} Tage buchen",
      "extra_day_message": "ℹ️ Ihre Rückgabezeit überschreitet 24 Stunden, daher wurde ein zusätzlicher Tag zur Miete hinzugefügt",
      "date_error_message": "Wählen Sie einen Mietzeitraum",
      "pickup_time_error_message": "Wählen Sie eine Abholzeit",
      "return_time_error_message": "Wählen Sie eine Rückgabezeit",
      "checking_availability": "Verfügbarkeit prüfen",
      // Меню
      "single_scooter": "Einzelner Mobilitätsroller",
      "double_scooter": "Doppelter Mobilitätsroller",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "Über uns",
      "contacts": "Kontakte",
      "choose_date": "Datum wählen",
      // Карточка скутера
      "scooter_sigle_from_price": "Ab 10€/Tag",
      "scooter_double_from_price": "Ab 20€/Tag",
      // Таблица с ценами
      "duration": "Tage",
      "price_name": "Preis",
      "day_single": "Tag",
      "day_plural": "Tage",
      "tariff_name": "Miettarife",
      "single_one": "Einzel",
      "double_one": "Doppel",
      // Локация офиса
      "our_office": "Unser Büro",
      "hours_title": "ÖFFNUNGSZEITEN",
      "working_days": "Montag bis Sonntag",
      "working_hours": "9:30 bis 17:00",
      "adress_title": "ADRESSE",
      "close": "Schließen",
      "open_maps": "In Google Maps öffnen",
      "map": "Karte",
      "how_get_button": "Wegbeschreibung",
      // Футер 
      "footer_heading": "Ein erstklassiger Mobilitätsscooter-Verleih in Teneriffa HotTickets",
      "copyright": "Alle Rechte vorbehalten",
      "aviso_legal": "Rechtlicher Hinweis",
      "privacidad": "Datenschutzrichtlinie",
      "cookies": "Cookie-Richtlinie",
      "terms_of_rent": "Mietbedingungen",
      "whatsapp_btn": "Auf WhatsApp schreiben",
      // Страница выбора даты
      "choose_date_heading": "Wählen Sie Ihre bevorzugten Daten für die Rollervermietung",
      "homepage": "Startseite",
      // О нас
      "about_text": "Willkommen bei der führenden Mobilitätsscooter-Vermietungsagentur im Süden Teneriffas. Mit jahrelanger Erfahrung sorgen wir für Ihren Komfort und Ihre Sicherheit mit unseren zuverlässigen Scootern. Unser Ruf basiert auf Vertrauen und exzellentem Service. Erkunden Sie Teneriffa mit Leichtigkeit und Vertrauen auf unseren gut gewarteten Scootern. Wählen Sie uns für ein unkompliziertes Mieterlebnis, das auf Ihre Mobilität und Ihr Vergnügen ausgerichtet ist.",
      "review_button": "Lesen Sie über 220 Bewertungen",
      // Бронирование 
      "choose_scooter": "Roller wählen",
      "choose_scooter_heading": "Wählen Sie den Rollertyp und die Menge",
      "continue": "Weiter",
      "select_btn": "Wählen",
      "add_to_selection": "Zur Auswahl hinzufügen",
      "total_amount": "Gesamt",
      "prepayment_online": "Online-Vorauszahlung",
      "payment_on_pickup": "Zahlung bei Abholung",
      "no_scooters_available": "⚠️ Leider sind keine Roller verfügbar. Bitte versuchen Sie, andere Daten auszuwählen.",
      // Персональные данные
      "first_name": "Vorname",
      "last_name": "Nachname",
      "hotel": "Hotel",
      "hotel_description": "Wo übernachten Sie?",
      "cell_number": "Telefonnummer",
      "cell_number_description": "Wir schicken Ihnen eine Bestätigungsnachricht über WhatsApp",
      "email": "E-Mail",
      "email_description": "Wir schicken Ihnen eine Bestätigungs-E-Mail",
      "personal_data": "Personenbezogene Daten",
      "personal_info_heading": "Fast geschafft 😊\nFüllen Sie Ihre Informationen aus",
      "required_field": "Dieses Feld ist erforderlich",
      "agree_to": "Ich habe gelesen und stimme zu",
      "terms_rent": "den Mietbedingungen",
      // Подтверждение бронирования
      "confirmation_heading": "Bitte überprüfen Sie die Daten und leisten Sie eine Vorauszahlung",
      "booking_info": "Buchungsinformationen",
      "rental_cost": "Mietkosten",
      "start_date": "Anfangsdatum",
      "end_date": "Enddatum",
      "return_duration": "Mietdauer",
      "prepayment_now": "Vorauszahlung jetzt",
      "deposit_on_pickup": "Kaution bei Abholung",
      "pay_btn": "20€ bezahlen",
      "edit": "Bearbeiten",
    }
  },
  "nl-BE": {
    translation: {
      // Главный экран
      "title": "Huur een mobiliteitsscooter in Zuid-Tenerife",
      "description": "Ontdek de vrijheid van bewegen op Tenerife met onze scoot verhuurservice",
      // Букинг форма
      "rental_period": "Huurperiode",
      "pickup_time": "Ophaaltijd",
      "return_time": "Terugbrengtijd",
      "select_placeholder": "Kies",
      "book_now": "Nu boeken",
      "book_for": "Boek voor {{count}} dagen",
      "book_for_plural": "Boek voor {{count}} dagen",
      "extra_day_message": "ℹ️ Uw terugbrengtijd overschrijdt 24 uur, dus er is een extra dag aan de huur toegevoegd",
      "checking_availability": "Beschikbaarheid controleren",
      // Меню
      "single_scooter": "Enkele mobiliteitsscooter",
      "double_scooter": "Dubbele mobiliteitsscooter",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "Over ons",
      "contacts": "Contacten",
      "choose_date": "Data kiezen",
      // Карточка скутера
      "scooter_sigle_from_price": "Vanaf 10€/dag",
      "scooter_double_from_price": "Vanaf 20€/dag",
      // Таблица с ценами
      "duration": "Dagen",
      "price_name": "Prijs",
      "day_single": "dag",
      "day_plural": "dagen",
      "tariff_name": "Huurprijzen",
      "single_one": "Enkel",
      "double_one": "Dubbel",
      // Локация офиса
      "our_office": "Ons kantoor",
      "hours_title": "WERKTIJDEN",
      "working_days": "Maandag tot Zondag",
      "working_hours": "9:30 tot 17:00",
      "adress_title": "ADRES",
      "close": "Sluiten",
      "open_maps": "Openen in Google Maps",
      "map": "Kaart",
      "how_get_button": "Hoe kom ik daar?",
      // Футер
      "footer_heading": "Een topservice voor het huren van mobiliteitsscooters op Tenerife HotTickets",
      "copyright": "Alle rechten voorbehouden",
      "aviso_legal": "Juridische kennisgeving",
      "privacidad": "Privacybeleid",
      "cookies": "Cookiebeleid",
      "terms_of_rent": "Huurvoorwaarden",
      "whatsapp_btn": "Schrijf op WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Kies je gewenste datums voor scooterverhuur",
      "homepage": "Startpagina",
      "date_error_message": "Selecteer een huurperiode",
      "pickup_time_error_message": "Selecteer een ophaaltijd",
      "return_time_error_message": "Selecteer een retourtijd",
       // О нас
       "about_text": "Welkom bij het toonaangevende verhuurbedrijf van mobiliteitsscooters in het zuiden van Tenerife. Met jarenlange ervaring zorgen we voor uw comfort en veiligheid met onze betrouwbare scooters. Onze reputatie is gebaseerd op vertrouwen en uitstekende service. Verken Tenerife met gemak en vertrouwen op onze goed onderhouden scooters. Kies voor ons voor een probleemloze verhuurervaring die gericht is op uw mobiliteit en plezier.",
       "review_button": "Lees 220+ beoordelingen",
       // Бронирование 
      "choose_scooter": "Kies scooter",
      "choose_scooter_heading": "Kies scootertype en aantal",
      "continue": "Doorgaan",
      "select_btn": "Kiezen",
      "add_to_selection": "Aan selectie toevoegen",
      "total_amount": "Totaal",
      "prepayment_online": "Online vooruitbetaling",
      "payment_on_pickup": "Betaling bij afhalen",
      "no_scooters_available": "⚠️ Er zijn helaas geen scooters beschikbaar. Probeer alstublieft andere data te selecteren.",
      // Персональные данные
      "first_name": "Voornaam",
      "last_name": "Achternaam",
      "hotel": "Hotel",
      "hotel_description": "Waar verblijf je?",
      "cell_number": "Telefoonnummer",
      "cell_number_description": "We sturen je een bevestigingsbericht via WhatsApp",
      "email": "E-mail",
      "email_description": "We sturen je een bevestigingsmail",
      "personal_data": "Persoonlijke gegevens",
      "personal_info_heading": "Bijna klaar 😊\nVul je gegevens in",
      "required_field": "Dit veld is verplicht",
      "agree_to": "Ik heb gelezen en ga akkoord met",
      "terms_rent": "de huurvoorwaarden",
      // Подтверждение бронирования
      "confirmation_heading": "Controleer de gegevens en doe een vooruitbetaling",
      "booking_info": "Boekingsinformatie",
      "rental_cost": "Huurkosten",
      "start_date": "Begindatum",
      "end_date": "Einddatum",
      "return_duration": "Huurperiode",
      "prepayment_now": "Vooruitbetaling nu",
      "deposit_on_pickup": "Borg bij afhalen",
      "pay_btn": "Betaal 20€",
      "edit": "Bewerken",
    }
  },
  nl: {
    translation: {
      // Главный экран
      "title": "Huur een mobiliteitsscooter in Zuid-Tenerife",
      "description": "Ontdek de vrijheid van bewegen op Tenerife met onze scoot verhuurservice",
      // Букинг форма
      "rental_period": "Huurperiode",
      "pickup_time": "Ophaaltijd",
      "return_time": "Retourtijd",
      "select_placeholder": "Kies",
      "book_now": "Nu boeken",
      "book_for": "Boek voor {{count}} dagen",
      "book_for_plural": "Boek voor {{count}} dagen",
      "extra_day_message": "ℹ️ Uw retourtijd overschrijdt 24 uur, dus er is een extra dag aan de huur toegevoegd",
      "date_error_message": "Selecteer een huurperiode",
      "pickup_time_error_message": "Selecteer een ophaaltijd",
      "return_time_error_message": "Selecteer een retourtijd",
      "checking_availability": "Beschikbaarheid controleren",
      // Меню
      "single_scooter": "Enkele mobiliteitsscooter",
      "double_scooter": "Dubbele mobiliteitsscooter",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "Over ons",
      "contacts": "Contacten",
      "choose_date": "Data kiezen",
      // Карточка скутера
      "scooter_sigle_from_price": "Vanaf 10€/dag",
      "scooter_double_from_price": "Vanaf 20€/dag",
      // Таблица с ценами
      "duration": "Dagen",
      "price_name": "Prijs",
      "day_single": "dag",
      "day_plural": "dagen",
      "tariff_name": "Huurprijzen",
      "single_one": "Enkel",
      "double_one": "Dubbel",
      // Локация офиса
      "our_office": "Ons kantoor",
      "hours_title": "WERKTIJDEN",
      "working_days": "Maandag tot Zondag",
      "working_hours": "9:30 tot 17:00",
      "adress_title": "ADRES",
      "close": "Sluiten",
      "open_maps": "Openen in Google Maps",
      "map": "Kaart",
      "how_get_button": "Hoe kom ik daar?",
      // Футер
      "footer_heading": "Een topservice voor het huren van mobiliteitsscooters op Tenerife HotTickets",
      "copyright": "Alle rechten voorbehouden",
      "aviso_legal": "Juridische kennisgeving",
      "privacidad": "Privacybeleid",
      "cookies": "Cookiebeleid",
      "terms_of_rent": "Huurvoorwaarden",
      "whatsapp_btn": "Schrijf op WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Kies je gewenste datums voor scooterverhuur",
      "homepage": "Startpagina",
      // О нас
      "about_text": "Welkom bij het toonaangevende verhuurbedrijf van mobiliteitsscooters in het zuiden van Tenerife. Met jarenlange ervaring zorgen we voor uw comfort en veiligheid met onze betrouwbare scooters. Onze reputatie is gebaseerd op vertrouwen en uitstekende service. Verken Tenerife met gemak en vertrouwen op onze goed onderhouden scooters. Kies voor ons voor een probleemloze verhuurervaring die gericht is op uw mobiliteit en plezier.",
      "review_button": "Lees 220+ beoordelingen",
      // Бронирование 
      "choose_scooter": "Kies scooter",
      "choose_scooter_heading": "Kies scootertype en aantal",
      "continue": "Doorgaan",
      "select_btn": "Kiezen",
      "add_to_selection": "Aan selectie toevoegen",
      "total_amount": "Totaal",
      "prepayment_online": "Online vooruitbetaling",
      "payment_on_pickup": "Betaling bij afhalen",
      "no_scooters_available": "⚠️ Er zijn helaas geen scooters beschikbaar. Probeer alstublieft andere data te selecteren.",
      // Персональные данные
      "first_name": "Voornaam",
      "last_name": "Achternaam",
      "hotel": "Hotel",
      "hotel_description": "Waar verblijf je?",
      "cell_number": "Telefoonnummer",
      "cell_number_description": "We sturen je een bevestigingsbericht via WhatsApp",
      "email": "E-mail",
      "email_description": "We sturen je een bevestigingsmail",
      "personal_data": "Persoonlijke gegevens",
      "personal_info_heading": "Bijna klaar 😊\nVul je gegevens in",
      "required_field": "Dit veld is verplicht",
      "agree_to": "Ik heb gelezen en ga akkoord met",
      "terms_rent": "de huurvoorwaarden",
      // Подтверждение бронирования
      "confirmation_heading": "Controleer de gegevens en doe een vooruitbetaling",
      "booking_info": "Boekingsinformatie",
      "rental_cost": "Huurkosten",
      "start_date": "Begindatum",
      "end_date": "Einddatum",
      "return_duration": "Huurperiode",
      "prepayment_now": "Vooruitbetaling nu",
      "deposit_on_pickup": "Borg bij afhalen",
      "pay_btn": "Betaal 20€",
      "edit": "Bewerken",
    }
  },
  fr: {
    translation: {
      // Главный экран
      "title": "Louez un scooter de mobilité dans le sud de Tenerife",
      "description": "Découvrez la liberté de vous déplacer à Tenerife avec notre service de location de scooters",
      // Букинг форма
      "rental_period": "Période de location",
      "pickup_time": "Heure de ramassage",
      "return_time": "Heure de retour",
      "select_placeholder": "Sélectionner",
      "book_now": "Réserver maintenant",
      "book_for": "Réserver pour {{count}} jours",
      "book_for_plural": "Réserver pour {{count}} jours",
      "extra_day_message": "ℹ️ Votre heure de retour dépasse 24 heures, donc un jour supplémentaire a été ajouté à la  location",
      "date_error_message": "Sélectionnez une période de location",
      "pickup_time_error_message": "Sélectionnez une heure de ramassage",
      "return_time_error_message": "Sélectionnez une heure de retour",
      "checking_availability": "Vérification de la disponibilité",
      // Меню
      "single_scooter": "Scooter de mobilité unique",
      "double_scooter": "Scooter de mobilité double",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "À propos de nous",
      "contacts": "Contacts",
      "choose_date": "Choisir les dates",
      // Карточка скутера
      "scooter_sigle_from_price": "À partir de 10€/jour",
      "scooter_double_from_price": "À partir de 10€/jour",
      // Таблица с ценами
      "duration": "Jours",
      "price_name": "Prix",
      "day_single": "jour",
      "day_plural": "jours",
      "tariff_name": "Tarifs de location",
      "single_one": "Simple",
      "double_one": "Double",
      // Локация офиса
      "our_office": "Notre bureau",
      "hours_title": "HEURES D'OUVERTURE",
      "working_days": "Lundi à Dimanche",
      "working_hours": "9:30 à 17:00",
      "adress_title": "ADRESSE",
      "close": "Fermer",
      "open_maps": "Ouvrir dans Google Maps",
      "map": "Carte",
      "how_get_button": "Comment y aller?",
      // Футер
      "footer_heading": "Un service de location de scooters de mobilité de premier ordre à Tenerife HotTickets",
      "copyright": "Tous droits réservés",
      "aviso_legal": "Mentions légales",
      "privacidad": "Politique de confidentialité",
      "cookies": "Politique de cookies",
      "terms_of_rent": "Conditions de location",
      "whatsapp_btn": "Écrire sur WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Choisissez vos dates préférées pour la location de scooters",
      "homepage": "Page d'accueil",
      // О нас
      "about_text": "Bienvenue chez l'agence leader de location de scooters de mobilité dans le sud de Tenerife. Avec des années d'expérience, nous garantissons votre confort et votre sécurité avec nos scooters fiables. Notre réputation est fondée sur la confiance et un service excellent. Explorez Tenerife avec facilité et confiance sur nos scooters bien entretenus. Choisissez-nous pour une expérience de location sans tracas axée sur votre mobilité et votre plaisir.",
      "review_button": "Lisez plus de 220 avis",
      // Бронирование 
      "choose_scooter": "Choisir scooter",
      "choose_scooter_heading": "Choisissez le type de scooter et la quantité",
      "continue": "Continuer",
      "select_btn": "Choisir",
      "add_to_selection": "Ajouter à la sélection",
      "total_amount": "Total",
      "prepayment_online": "Prépaiement en ligne",
      "payment_on_pickup": "Paiement à la livraison",
      "no_scooters_available": "⚠️ Malheureusement, il n'y a pas de scooters disponibles. Veuillez essayer de choisir d'autres dates.",
      // Персональные данные
      "first_name": "Prénom",
      "last_name": "Nom",
      "hotel": "Hôtel",
      "hotel_description": "Où séjournez-vous?",
      "cell_number": "Numéro de téléphone",
      "cell_number_description": "Nous vous enverrons un message de confirmation sur WhatsApp",
      "email": "E-mail",
      "email_description": "Nous vous enverrons un e-mail de confirmation",
      "personal_data": "Données personnelles",
      "personal_info_heading": "Presque terminé 😊\nRemplissez vos informations",
      "required_field": "Ce champ est obligatoire",
      "agree_to": "J'ai lu et j'accepte",
      "terms_rent": "les conditions de location",
      // Подтверждение бронирования
      "confirmation_heading": "Veuillez vérifier les informations et effectuer un prépaiement",
      "booking_info": "Informations de réservation",
      "rental_cost": "Coût de la location",
      "start_date": "Date de début",
      "end_date": "Date de fin",
      "return_duration": "Durée de la location",
      "prepayment_now": "Prépaiement maintenant",
      "deposit_on_pickup": "Dépôt à la livraison",
      "pay_btn": "Payer 20€",
      "edit": "Modifier"
    }
  },
  is: {
    translation: {
      // Главный экран
      "title": "Leigðu hreyfanlega vespu á Tenerife Suður",
      "description": "Uppgötvaðu frelsið að ferðast um Tenerife með vespu leiguþjónustu okkar",
      // Букинг форма
      "rental_period": "Leigutímabil",
      "pickup_time": "Sóttími",
      "return_time": "Skilunartími",
      "select_placeholder": "Velja",
      "book_now": "Bóka núna",
      "book_for": "Bóka fyrir {{count}} daga",
      "book_for_plural": "Bóka fyrir {{count}} daga",
      "extra_day_message": "ℹ️ Skilunartími þinn fer yfir 24 klukkustundir, svo einn auka dagur hefur verið bætt við   leiguna",
      "date_error_message": "Veldu leigutímabil",
      "pickup_time_error_message": "Veldu sóttíma",
      "return_time_error_message": "Veldu skilatíma",
      "checking_availability": "Athuga framboð",
      // Меню
      "single_scooter": "Einhleypur hreyfanlegur vespa",
      "double_scooter": "Tvöfaldur hreyfanlegur vespa",
      "selected_scooters": "{{count}} {{scooterType}}",
      "about_us": "Um okkur",
      "contacts": "Tengiliðir",
      "choose_date": "Veldu dagsetningar",
      // Карточка скутера
      "scooter_sigle_from_price": "Frá 10€/dagur",
      "scooter_double_from_price": "Frá 20€/dagur",
      // Таблица с ценами
      "duration": "Dagar",
      "price_name": "Verð",
      "day_single": "dagur",
      "day_plural": "dagar",
      "tariff_name": "Leigugjöld",
      "single_one": "Einn",
      "double_one": "Tveir",
      // Локация офиса
      "our_office": "Skrifstofa okkar",
      "hours_title": "VINNUTÍMAR",
      "working_days": "Mánudagur til Sunnudagur",
      "working_hours": "9:30 til 17:00",
      "adress_title": "HEIMILISFANG",
      "close": "Loka",
      "open_maps": "Opna í Google kortum",
      "map": "Kort",
      "how_get_button": "Hvernig á að komast þangað?",
      // Футер 
      "footer_heading": "Topphjólaleiga á Tenerife HotTickets",
      "copyright": "Öll réttindi áskilin",
      "aviso_legal": "Lagaleg tilkynning",
      "privacidad": "Persónuverndarstefna",
      "cookies": "Vafrakökustefna",
      "terms_of_rent": "Skilmálar leigu",
      "whatsapp_btn": "Skrifa á WhatsApp",
      // Страница выбора даты
      "choose_date_heading": "Veldu þínar ákjósanlegu dagsetningar fyrir skútuleigu",
      "homepage": "Heimasíða",
      // О нас
      "about_text": "Velkomin í fremstu hjólaleigu á suðurhluta Tenerife. Með margra ára reynslu tryggjum við þægindi þín og öryggi með áreiðanlegum hjólum okkar. Orðspor okkar byggir á trausti og frábærri þjónustu. Kannaðu Tenerife með auðveldum og öruggum hætti á vel viðhaldið hjólum okkar. Veldu okkur fyrir vandræðalausa leiguupplifun með áherslu á hreyfanleika þína og ánægju.",
      "review_button": "Lestu 220+ umsagnir",
      // Бронирование 
      "choose_scooter": "Velja skellinöðru",
      "choose_scooter_heading": "Veldu skellinöðrutegund og magn",
      "continue": "Halda áfram",
      "select_btn": "Velja",
      "add_to_selection": "Bæta við val",
      "total_amount": "Samtals",
      "prepayment_online": "Forskoðun á netinu",
      "payment_on_pickup": "Greiðsla við afhendingu",
      "no_scooters_available": "⚠️ Því miður eru engir skútur tiltækir. Vinsamlegast reyndu að velja aðrar dagsetningar.",
      // Персональные данные 
      "first_name": "Fornafn",
      "last_name": "Eftirnafn",
      "hotel": "Hótel",
      "hotel_description": "Hvar gistir þú?",
      "cell_number": "Símanúmer",
      "cell_number_description": "Við munum senda þér skilaboð á WhatsApp til að staðfesta",
      "email": "Netfang",
      "email_description": "Við munum senda staðfestingarpóst",
      "personal_data": "Persónuupplýsingar",
      "personal_info_heading": "Næstum búið 😊\nFylltu inn upplýsingarnar þínar",
      "required_field": "Þetta svæði er krafist",
      "agree_to": "Ég hef lesið og er samþykkur",
      "terms_rent": "skilmálum leigu",
      // Подтверждение бронирования
      "confirmation_heading": "Vinsamlegast athugaðu upplýsingarnar og gerðu fyrirframgreiðslu",
      "booking_info": "Bókunarupplýsingar",
      "rental_cost": "Leigukostnaður",
      "start_date": "Upphafsdagur",
      "end_date": "Lokadagur",
      "return_duration": "Leigutími",
      "prepayment_now": "Forföllun núna",
      "deposit_on_pickup": "Innborgun við afhendingu",
      "pay_btn": "Borga 20€",
      "edit": "Breyta"
    }
  }
};

const detectionOptions = {
  order: ['localStorage', 'navigator', 'querystring', 'cookie', 'sessionStorage', 'htmlTag'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  htmlTag: document.documentElement,
  cookieOptions: { path: '/', sameSite: 'strict' }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: detectionOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already does escaping
    },
    pluralSeparator: '_', 
    nsSeparator: false, 
    keySeparator: false, 
  });

export default i18n;